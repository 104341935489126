import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabaseServiceRoleKey = import.meta.env.VITE_SUPABASE_SERVICE_ROLE_KEY;

if (!supabaseUrl || !supabaseAnonKey || !supabaseServiceRoleKey) {
  throw new Error('Missing Supabase environment variables');
}

// Create a singleton instance
class SupabaseClient {
  private static instance: ReturnType<typeof createClient<Database>>;
  private static adminInstance: ReturnType<typeof createClient<Database>>;

  public static getInstance() {
    if (!this.instance) {
      this.instance = createClient<Database>(supabaseUrl, supabaseAnonKey, {
        db: {
          schema: 'public'
        },
        global: {
          headers: {
            'x-my-custom-header': 'my-app-name'
          }
        },
        auth: { persistSession: true }
      });
    }
    return this.instance;
  }

  public static getAdminInstance() {
    if (!this.adminInstance) {
      this.adminInstance = createClient<Database>(supabaseUrl, supabaseServiceRoleKey, {
        auth: {
          autoRefreshToken: false,
          persistSession: false
        }
      });
    }
    return this.adminInstance;
  }
}

export const supabase = SupabaseClient.getInstance();
const supabaseAdmin = SupabaseClient.getAdminInstance();

// Platform functions
export interface Platform {
  id?: string;
  slug: string;
  name: string;
  short_description: string;
  long_description: string;
  features: string[];
  link: string;
  created_at?: string;
  updated_at?: string;
  status: 'draft' | 'published' | 'archived';
}

export const createPlatform = async (platformData: Omit<Platform, 'id' | 'created_at' | 'updated_at'>): Promise<Platform> => {
  try {
    console.log('Creating platform with data:', platformData);

    // Validate required fields
    if (!platformData.name) throw new Error('Name is required');
    if (!platformData.slug) throw new Error('Slug is required');
    if (!platformData.short_description) throw new Error('Short description is required');
    if (!platformData.long_description) throw new Error('Long description is required');
    if (!platformData.link) throw new Error('Link is required');
    if (!platformData.link.match(/^https?:\/\/.+/)) throw new Error('Link must be a valid URL');

    // Add timestamps
    const now = new Date().toISOString();
    const dataWithTimestamps = {
      ...platformData,
      created_at: now,
      updated_at: now
    };

    const { data, error } = await supabase
      .from('platforms')
      .insert([dataWithTimestamps])
      .select()
      .single();

    if (error) {
      console.error('Supabase error:', error);
      throw new Error(error.message);
    }

    if (!data) {
      throw new Error('No data returned after insert');
    }

    console.log('Platform created successfully:', data);
    return data;
  } catch (error) {
    console.error('Error in createPlatform:', error);
    throw error;
  }
};

export const getPlatforms = async (): Promise<Platform[]> => {
  try {
    const { data, error } = await supabase
      .from('platforms')
      .select('*')
      .order('name', { ascending: true });

    if (error) throw error;
    if (!data) return [];

    return data;
  } catch (error) {
    console.error('Error in getPlatforms:', error);
    throw error;
  }
};

export const getPlatformById = async (id: string): Promise<Platform | null> => {
  try {
    if (!id) {
      console.warn('Attempted to fetch platform with empty ID');
      return null;
    }

    // Basic validation for UUID format
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (!uuidRegex.test(id)) {
      console.warn('Invalid UUID format:', id);
      return null;
    }

    const { data, error } = await supabase
      .from('platforms')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in getPlatformById:', error);
    throw error;
  }
};

export const getPlatformBySlug = async (slug: string): Promise<Platform | null> => {
  try {
    if (!slug) {
      console.warn('Attempted to fetch platform with empty slug');
      return null;
    }

    const { data, error } = await supabase
      .from('platforms')
      .select('*')
      .eq('slug', slug)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in getPlatformBySlug:', error);
    throw error;
  }
};

export const updatePlatform = async (id: string, data: Partial<Platform>): Promise<Platform> => {
  try {
    const { data: updatedPlatform, error } = await supabase
      .from('platforms')
      .update({
        ...data,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    if (!updatedPlatform) {
      throw new Error('No data returned after update');
    }

    return updatedPlatform;
  } catch (error) {
    console.error('Error in updatePlatform:', error);
    throw error;
  }
};

export const deletePlatform = async (id: string): Promise<void> => {
  try {
    const { error } = await supabase
      .from('platforms')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error in deletePlatform:', error);
    throw error;
  }
};

// Auth functions
export const loginWithEmail = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password
  });
  
  if (error) throw error;
  return data;
};

export const logout = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      console.log('No active session to logout from');
      return;
    }

    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const onAuthChange = (callback: (session: any) => void) => {
  return supabase.auth.onAuthStateChange((event, session) => {
    callback(session);
  });
};

// Contact functions
export interface Contact {
  id?: string;
  type: string;
  name: string;
  email: string;
  message: string;
  created_at?: string;
  status?: string;
}

export const addContact = async (contactData: Omit<Contact, 'created_at' | 'status'>) => {
  try {
    const { data, error } = await supabaseAdmin
      .from('leads')
      .insert([{
        ...contactData,
        status: 'new'
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    throw error;
  }
};

export const getContacts = async (): Promise<Contact[]> => {
  try {
    const { data, error } = await supabase
      .from('leads')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateContact = async (id: string, data: Partial<Contact>) => {
  try {
    const { data: updatedContact, error } = await supabase
      .from('leads')
      .update(data)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return updatedContact;
  } catch (error) {
    throw error;
  }
};

// Glossary functions
export interface GlossaryTerm {
  id?: string;
  slug: string;
  title: string;
  description: string;
  content: string;
  icon: string;
  color: string;
  created_at: string;
  updated_at: string;
  published_at: string | null;
  status: 'draft' | 'published' | 'archived';
  seo_title: string;
  seo_description: string;
}

export const createGlossaryTerm = async (termData: Omit<GlossaryTerm, 'id' | 'created_at' | 'updated_at'>): Promise<GlossaryTerm> => {
  try {
    const { data, error } = await supabase
      .from('glossary')
      .insert([{
        ...termData,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in createGlossaryTerm:', error);
    throw error;
  }
};

export const getGlossaryTerms = async (): Promise<GlossaryTerm[]> => {
  try {
    const { data, error } = await supabase
      .from('glossary')
      .select('*')
      .order('title', { ascending: true });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in getGlossaryTerms:', error);
    throw error;
  }
};

export const getGlossaryTermBySlug = async (slug: string): Promise<GlossaryTerm | null> => {
  try {
    const { data, error } = await supabase
      .from('glossary')
      .select('*')
      .eq('slug', slug)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in getGlossaryTermBySlug:', error);
    throw error;
  }
};

export const updateGlossaryTerm = async (id: string, data: Partial<GlossaryTerm>) => {
  try {
    const { data: updatedTerm, error } = await supabase
      .from('glossary')
      .update({
        ...data,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return updatedTerm;
  } catch (error) {
    console.error('Error in updateGlossaryTerm:', error);
    throw error;
  }
};

export const deleteGlossaryTerm = async (id: string): Promise<void> => {
  try {
    const { error } = await supabase
      .from('glossary')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error in deleteGlossaryTerm:', error);
    throw error;
  }
};