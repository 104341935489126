import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthChange, supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

export default function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check current session first
    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          console.log('No active session found');
          navigate('/admin/login');
          toast.error('Please log in to continue');
        }
      } catch (error) {
        console.error('Error checking session:', error);
        navigate('/admin/login');
        toast.error('Authentication error');
      }
      setLoading(false);
    };

    checkSession();

    // Set up auth state listener
    const { data: { subscription } } = onAuthChange((session) => {
      if (!session && initialized) {
        console.log('Session ended, redirecting to login');
        navigate('/admin/login');
        toast.error('Session expired. Please log in again.');
      }
      setLoading(false);
      setInitialized(true);
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [navigate, initialized]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return <>{children}</>;
}