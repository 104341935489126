import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Globe, ArrowLeft } from 'lucide-react';
import { getPlatformBySlug, type Platform } from '../../lib/supabase';

export default function PlatformDetail() {
  const { slug } = useParams<{ slug: string }>();
  const [platform, setPlatform] = useState<Platform | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPlatform = async () => {
      if (!slug) return;
      
      try {
        const data = await getPlatformBySlug(slug);
        setPlatform(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Error loading platform');
      } finally {
        setLoading(false);
      }
    };

    loadPlatform();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error || !platform) {
    return (
      <div className="text-center py-8">
        <p className="text-red-400 mb-4">
          {error || 'Platform not found'}
        </p>
        <Link
          to="/plataformas/"
          className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors"
        >
          <ArrowLeft className="w-4 h-4" />
          <span>Back to platforms</span>
        </Link>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{platform.name} - Video Empresas</title>
        <meta name="description" content={platform.short_description} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": platform.name,
            "description": platform.short_description,
            "url": platform.link,
            "applicationCategory": "Video Software",
            "offers": {
              "@type": "Offer",
              "availability": "https://schema.org/OnlineOnly"
            }
          })}
        </script>
      </Helmet>

      <article className="max-w-3xl mx-auto prose prose-invert">
        <div className="flex items-center gap-4 mb-6">
          <div className="h-12 w-12 bg-blue-900/50 rounded-xl flex items-center justify-center shrink-0">
            <Globe className="h-6 w-6 text-blue-400" />
          </div>
          <div className="h-[1px] bg-gradient-to-r from-blue-500/50 to-transparent flex-1" />
        </div>

        <h1 className="text-4xl font-bold text-white mb-4">{platform.name}</h1>
        <p className="text-xl text-gray-300 mb-8">{platform.short_description}</p>

        <div className="text-gray-300">
          <p>{platform.long_description}</p>

          <h2>Características principales</h2>
          <ul>
            {platform.features.map((feature, index) => (
              <li key={index} className="flex items-center gap-3">
                <span className="w-1.5 h-1.5 bg-blue-500 rounded-full shrink-0"></span>
                {feature}
              </li>
            ))}
          </ul>

          <div className="mt-12">
            <a
              href={platform.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Globe className="w-5 h-5" />
              <span>Visitar plataforma</span>
            </a>
          </div>
        </div>
      </article>
    </>
  );
}