import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Globe } from 'lucide-react';
import { getPlatforms, type Platform } from '../../lib/supabase';

export default function PlatformsIndex() {
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPlatforms = async () => {
      try {
        const data = await getPlatforms();
        setPlatforms(data.filter(platform => platform.status === 'published'));
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Error loading platforms');
      } finally {
        setLoading(false);
      }
    };

    loadPlatforms();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-400 py-8">
        <p>Error loading platforms: {error}</p>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Plataformas de Video AI - Video Empresas</title>
        <meta name="description" content="Descubre las mejores plataformas de inteligencia artificial para la creación y edición de videos. Compara características, precios y funcionalidades." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListElement": platforms.map((platform, index) => ({
              "@type": "SoftwareApplication",
              "position": index + 1,
              "name": platform.name,
              "description": platform.short_description,
              "url": platform.link,
              "applicationCategory": "Video Software",
              "offers": {
                "@type": "Offer",
                "availability": "https://schema.org/OnlineOnly"
              }
            })),
            "numberOfItems": platforms.length,
            "itemListOrder": "Unordered",
            "name": "Plataformas de Video AI",
            "description": "Las mejores plataformas de inteligencia artificial para video"
          })}
        </script>
      </Helmet>

      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-white mb-6">Plataformas de Video AI</h1>
        <p className="text-gray-300 mb-12">
          Explora nuestra selección de las mejores plataformas de inteligencia artificial para la creación y edición de videos. 
          Compara características, funcionalidades y encuentra la solución perfecta para tus necesidades.
        </p>

        <div className="grid gap-6">
          {platforms.map((platform) => (
            <Link
              key={platform.id}
              to={`/plataformas/${platform.slug}/`}
              className="bg-gray-800 rounded-xl p-6 border border-gray-700 hover:border-blue-500 transition-all group"
            >
              <div>
                <div className="h-10 w-10 bg-blue-900/50 rounded-lg flex items-center justify-center mb-4">
                  <Globe className="h-5 w-5 text-blue-400" />
                </div>
                <h2 className="text-xl font-semibold text-white mb-2">{platform.name}</h2>
                <p className="text-gray-300">{platform.short_description}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}