import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as Icons from 'lucide-react';
import { 
  getGlossaryTermBySlug, 
  updateGlossaryTerm, 
  createGlossaryTerm,
  deleteGlossaryTerm,
  type GlossaryTerm 
} from '../../lib/supabase';

const AVAILABLE_COLORS = [
  'blue',
  'purple',
  'pink',
  'green',
  'yellow',
  'red',
  'gray'
];

const AVAILABLE_ICONS = [
  'BarChart2',
  'Heart',
  'ArrowLeft',
  'MousePointerClick',
  'Search',
  'Building',
  'Sparkles',
  'PenTool',
  'Timer'
];

const DEFAULT_TERM: Omit<GlossaryTerm, 'id' | 'created_at' | 'updated_at'> = {
  title: '',
  slug: '',
  description: '',
  content: '',
  icon: 'Book',
  color: 'blue',
  status: 'draft',
  published_at: null,
  seo_title: '',
  seo_description: ''
};

export default function GlossaryEditor() {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [term, setTerm] = useState<GlossaryTerm | null>(null);
  const isNew = slug === 'new';

  useEffect(() => {
    const loadTerm = async () => {
      if (isNew) {
        setTerm(DEFAULT_TERM as GlossaryTerm);
        setLoading(false);
        return;
      }
      
      try {
        const data = await getGlossaryTermBySlug(slug!);
        if (data) {
          setTerm(data);
        }
      } catch (err) {
        toast.error('Error loading term');
      } finally {
        setLoading(false);
      }
    };

    loadTerm();
  }, [slug, isNew]);

  const handleSave = async () => {
    if (!term) return;

    setSaving(true);
    try {
      if (isNew) {
        await createGlossaryTerm(term);
        toast.success('Term created successfully');
      } else {
        await updateGlossaryTerm(term.id!, term);
        toast.success('Term updated successfully');
      }
      navigate('/admin/glossary');
    } catch (error) {
      toast.error(isNew ? 'Error creating term' : 'Error updating term');
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!term?.id || !window.confirm('Are you sure you want to delete this term?')) return;

    setDeleting(true);
    try {
      await deleteGlossaryTerm(term.id);
      toast.success('Term deleted successfully');
      navigate('/admin/glossary');
    } catch (error) {
      toast.error('Error deleting term');
    } finally {
      setDeleting(false);
    }
  };

  const generateSlug = (title: string) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setTerm(prev => {
      if (!prev) return null;
      return {
        ...prev,
        title: newTitle,
        slug: isNew ? generateSlug(newTitle) : prev.slug,
        seo_title: isNew ? `${newTitle} - Video Empresas` : prev.seo_title
      };
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!term) {
    return (
      <div className="text-center text-red-600 py-8">
        Term not found
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold text-gray-900">
            {isNew ? 'Create New Term' : 'Edit Glossary Term'}
          </h1>
          <div className="space-x-2">
            <button
              onClick={() => navigate('/admin/glossary')}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            {!isNew && (
              <button
                onClick={handleDelete}
                disabled={deleting}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50"
              >
                {deleting ? 'Deleting...' : 'Delete'}
              </button>
            )}
            <button
              onClick={handleSave}
              disabled={saving}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
            >
              {saving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 space-y-6">
        {/* Basic Information */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              value={term.title}
              onChange={handleTitleChange}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Slug
            </label>
            <input
              type="text"
              value={term.slug}
              onChange={(e) => setTerm({ ...term, slug: e.target.value })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        {/* Description */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            value={term.description}
            onChange={(e) => setTerm({ ...term, description: e.target.value })}
            rows={3}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {/* Icon and Color */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Icon
            </label>
            <select
              value={term.icon}
              onChange={(e) => setTerm({ ...term, icon: e.target.value })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              {AVAILABLE_ICONS.map((icon) => (
                <option key={icon} value={icon}>
                  {icon}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Color
            </label>
            <select
              value={term.color}
              onChange={(e) => setTerm({ ...term, color: e.target.value })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              {AVAILABLE_COLORS.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Status */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status
          </label>
          <select
            value={term.status}
            onChange={(e) => setTerm({ ...term, status: e.target.value as GlossaryTerm['status'] })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
            <option value="archived">Archived</option>
          </select>
        </div>

        {/* Content Editor */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Content
          </label>
          <textarea
            value={term.content}
            onChange={(e) => setTerm({ ...term, content: e.target.value })}
            rows={20}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent font-mono"
            placeholder="Enter HTML content here..."
          />
        </div>

        {/* SEO Fields */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-gray-900">SEO Settings</h3>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              SEO Title
            </label>
            <input
              type="text"
              value={term.seo_title}
              onChange={(e) => setTerm({ ...term, seo_title: e.target.value })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              SEO Description
            </label>
            <textarea
              value={term.seo_description}
              onChange={(e) => setTerm({ ...term, seo_description: e.target.value })}
              rows={3}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
      </div>
    </div>
  );
}