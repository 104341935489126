import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Globe } from 'lucide-react';
import { 
  createPlatform,
  updatePlatform,
  deletePlatform,
  getPlatformById,
  type Platform 
} from '../../lib/supabase';

const DEFAULT_PLATFORM: Omit<Platform, 'id' | 'created_at' | 'updated_at'> = {
  name: '',
  slug: '',
  short_description: '',
  long_description: '',
  features: [],
  link: 'https://',
  status: 'draft'
};

export default function PlatformEditor() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [platform, setPlatform] = useState<Platform | null>(null);
  const isNew = id === 'new';

  useEffect(() => {
    const loadPlatform = async () => {
      if (isNew) {
        setPlatform(DEFAULT_PLATFORM as Platform);
        setLoading(false);
        return;
      }

      try {
        if (!id) {
          toast.error('Invalid platform ID');
          navigate('/admin/platforms');
          return;
        }

        const data = await getPlatformById(id);
        if (!data) {
          toast.error('Platform not found');
          navigate('/admin/platforms');
          return;
        }
        setPlatform(data);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error loading platform';
        toast.error(errorMessage);
        navigate('/admin/platforms');
      } finally {
        setLoading(false);
      }
    };

    loadPlatform();
  }, [id, isNew, navigate]);

  const generateSlug = (name: string) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  const validatePlatform = (data: Platform) => {
    if (!data.name.trim()) return 'Name is required';
    if (!data.slug.trim()) return 'Slug is required';
    if (!data.short_description.trim()) return 'Short description is required';
    if (!data.long_description.trim()) return 'Long description is required';
    if (!data.link.trim()) return 'Link is required';
    if (!data.link.match(/^https?:\/\/.+/)) return 'Link must be a valid URL starting with http:// or https://';
    if (data.features.some(f => !f.trim())) return 'All features must have content';
    return null;
  };

  const handleSave = async () => {
    if (!platform) return;

    const validationError = validatePlatform(platform);
    if (validationError) {
      toast.error(validationError);
      return;
    }

    setSaving(true);
    try {
      if (isNew) {
        await createPlatform(platform);
        toast.success('Platform created successfully');
      } else if (platform.id) {
        await updatePlatform(platform.id, platform);
        toast.success('Platform updated successfully');
      }
      navigate('/admin/platforms');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Error saving platform';
      toast.error(errorMessage);
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!platform?.id || !window.confirm('Are you sure you want to delete this platform?')) return;

    setDeleting(true);
    try {
      await deletePlatform(platform.id);
      toast.success('Platform deleted successfully');
      navigate('/admin/platforms');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Error deleting platform';
      toast.error(errorMessage);
    } finally {
      setDeleting(false);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setPlatform(prev => {
      if (!prev) return null;
      return {
        ...prev,
        name,
        slug: isNew ? generateSlug(name) : prev.slug
      };
    });
  };

  const handleFeatureChange = (index: number, value: string) => {
    if (!platform) return;
    
    const newFeatures = [...platform.features];
    newFeatures[index] = value;
    setPlatform({ ...platform, features: newFeatures });
  };

  const addFeature = () => {
    if (!platform) return;
    setPlatform({ ...platform, features: [...platform.features, ''] });
  };

  const removeFeature = (index: number) => {
    if (!platform) return;
    const newFeatures = platform.features.filter((_, i) => i !== index);
    setPlatform({ ...platform, features: newFeatures });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!platform) {
    return (
      <div className="text-center text-red-600 py-8">
        Platform not found
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold text-gray-900">
            {isNew ? 'Create New Platform' : 'Edit Platform'}
          </h1>
          <div className="space-x-2">
            <button
              onClick={() => navigate('/admin/platforms')}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            {!isNew && (
              <button
                onClick={handleDelete}
                disabled={deleting}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50"
              >
                {deleting ? 'Deleting...' : 'Delete'}
              </button>
            )}
            <button
              onClick={handleSave}
              disabled={saving}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
            >
              {saving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 space-y-6">
        {/* Basic Information */}
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              value={platform.name}
              onChange={handleNameChange}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Platform name"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Slug
            </label>
            <input
              type="text"
              value={platform.slug}
              onChange={(e) => setPlatform({ ...platform, slug: e.target.value })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="platform-name"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Link
          </label>
          <input
            type="url"
            value={platform.link}
            onChange={(e) => setPlatform({ ...platform, link: e.target.value })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="https://"
            required
          />
        </div>

        {/* Descriptions */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Short Description
          </label>
          <input
            type="text"
            value={platform.short_description}
            onChange={(e) => setPlatform({ ...platform, short_description: e.target.value })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Brief description of the platform"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Long Description
          </label>
          <textarea
            value={platform.long_description}
            onChange={(e) => setPlatform({ ...platform, long_description: e.target.value })}
            rows={4}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Detailed description of the platform"
            required
          />
        </div>

        {/* Features */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Features
            </label>
            <button
              onClick={addFeature}
              type="button"
              className="text-sm text-blue-600 hover:text-blue-800"
            >
              + Add feature
            </button>
          </div>
          <div className="space-y-2">
            {platform.features.map((feature, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  value={feature}
                  onChange={(e) => handleFeatureChange(index, e.target.value)}
                  className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Platform feature"
                />
                <button
                  onClick={() => removeFeature(index)}
                  type="button"
                  className="px-3 py-2 text-red-600 hover:text-red-800"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Status */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status
          </label>
          <select
            value={platform.status}
            onChange={(e) => setPlatform({ ...platform, status: e.target.value as Platform['status'] })}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
            <option value="archived">Archived</option>
          </select>
        </div>
      </div>
    </div>
  );
}